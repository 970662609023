import React from "react"
import styled from "styled-components"
import { Colors } from "../styled-components/variables"
import { H2, H3, ResponsiveContainer } from "../styled-components/Layout"

const Wrapper = styled.div`
  background: ${Colors.MainDark};
  padding: 120px 0;
  text-align: center;
  clip-path: polygon(0% 0%, 100% 4%, 100% 96%, 0% 100%);
  margin-top: -50px;
`
const Row = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`
const Col = styled.div``

const WhiteH2 = styled(H2)`
  color: ${Colors.ButtonText};
  margin-bottom: 60px;
`

const OrangeH3 = styled(H3)`
  color: ${Colors.MainOrange};
  font-weight: normal;
  transform: scaleY(1.8);
  margin: 20px 0 50px;
`

const Text = styled.p`
  color: ${Colors.ButtonText};
  padding: 0 40px;
  line-height: 1.7;
  font-size: 1.1rem;
  font-weight: normal;
`

const OurServices: React.FC = () => {
  return (
    <Wrapper>
      <ResponsiveContainer>
        <WhiteH2>Services</WhiteH2>
        <Row>
          <Col>
            <OrangeH3>
              Business branding landing page. <br /> Effective.
            </OrangeH3>
            <Text>
              We know. Starting a business is tough. We make the process a tad
              simpler by providing you with a high-quality, SEO-optimised
              website, tailored to your business needs. Design and
              implementation. And of course, it will look equally awesome on any
              device.
            </Text>
          </Col>
          <Col>
            <OrangeH3>
              Full-fledged web applications. <br /> With everything inside.
            </OrangeH3>
            <Text>
              A blog for your findings of new species of bees? No problem. An
              ecommerce platform to sell your custom-made socks? No problem. A
              subscription platform to showcase your pre-recorded doll-theatre
              videos? No problem. If it needs smartness, you are covered.
            </Text>
          </Col>
        </Row>
      </ResponsiveContainer>
    </Wrapper>
  )
}

export default OurServices
