export enum Colors {
  // Main colors.
  MainDark = "#212121",
  MainOrange = "#c77e60",

  // Situational colors.
  HeaderBackground = "#f3f4e9",
  Background = "#f2f2f2",
  ButtonText = "#fff",
  InputPlaceholder = "#d3d3d3",
}
