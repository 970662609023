import React from "react"
import styled from "styled-components"

import { Colors } from "../styled-components/variables"

const Container = styled.div`
  background: ${Colors.MainDark};
  color: ${Colors.ButtonText};
  text-align: center;
  margin: 0;
  padding: 20px 0;
  width: 100%;
  clip-path: polygon(0% 4%, 100% 0%, 100% 100%, 0% 100%);
`

const Footer: React.FC = () => {
  return <Container>DigitalMelon is tranding under DigitalMelon Ltd.</Container>
}

export default Footer
