import React from "react"
import { Reset } from "styled-reset"
import { createGlobalStyle } from "styled-components"

import BodyWrapper from "../styled-components/BodyWrapper"
import Hero from "../core/Hero"
import OurServices from "../core/OurServices"
import ContactUs from "../core/ContactUs"
import Footer from "../core/Footer"
import Typography from "../styled-components/Typography"
import AwesomeButtonConfig from "../styled-components/AwesomeButtonConfig"
import Seo from "../core/Seo"

const GlobalStyles = createGlobalStyle`
  ${Typography}
  ${AwesomeButtonConfig}
`

const Home: React.FC = () => {
  return (
    <>
      <Reset />
      <GlobalStyles />

      <Seo />

      <BodyWrapper>
        <Hero />
        <OurServices />
        <ContactUs />
        <Footer />
      </BodyWrapper>
    </>
  )
}

export default Home
