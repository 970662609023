import styled from "styled-components"

const BodyWrapper = styled.div`
  background: #f2f2f2;

  * {
    box-sizing: border-box;
  }
`

export default BodyWrapper
