import React from "react"
// @ts-ignore
import { AwesomeButton } from "react-awesome-button"
import "react-awesome-button/dist/styles.css"

const Button: React.FC = ({ children }) => {
  return <AwesomeButton type="primary">{children}</AwesomeButton>
}

export default Button
