import React from "react"
import styled from "styled-components"

import { ResponsiveContainer } from "../styled-components/Layout"
import Button from "../styled-components/Button"
import { graphql, useStaticQuery } from "gatsby"

const Left = styled.div`
  //flex-grow: 1;
`

const Right = styled.div`
  display: flex;
`

const HeaderContainer = styled.div`
  display: flex;
  padding: 16px 0;

  ${Left} {
    flex-grow: 1;
  }

  ${Right} {
    flex-grow: 0;
    display: flex;
    align-items: center;
  }
`

const Header: React.FC = () => {
  const imagesQueryData = useStaticQuery(graphql`
    query LogoQuery {
      logo: file(relativePath: { eq: "logo.png" }) {
        publicURL
      }
    }
  `)

  return (
    <ResponsiveContainer>
      <HeaderContainer>
        <Left>
          <img src={imagesQueryData.logo.publicURL} />
        </Left>
        <Right>
          <a href="#contact-us">
            <Button>Get Quote</Button>
          </a>
        </Right>
      </HeaderContainer>
    </ResponsiveContainer>
  )
}

export default Header
