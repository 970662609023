import { css } from "styled-components"

const AwesomeButtonConfig = css`
  .aws-btn {
    --button-default-height: 54px;
    --button-primary-color: #313131;
    --button-primary-color-dark: #121212;
    --button-primary-color-light: #ffffff;
    --button-primary-color-hover: #181818;
    --button-primary-color-active: #232323;
    --button-primary-border: none;
    --button-raise-level: 8px;

    .aws-btn__content > span {
      font-family: "Merriweather", sans-serif;
      font-size: 1rem;
      padding: 0 20px;
    }
  }
`

export default AwesomeButtonConfig
