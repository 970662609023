import styled from "styled-components"
import { Colors } from "./variables"

export const ResponsiveContainer = styled.div`
  position: relative;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
`

export const H2 = styled.h2`
  font-size: 2.8rem;
  font-weight: bold;
  display: block;
`

export const H2Subtitle = styled.p`
  font-family: Merriweather, serif;
  color: #666;
  font-size: 1.4rem;
  font-weight: bold;
  display: block;
`

export const H3 = styled.h3`
  font-size: 1.6rem;
  font-weight: bold;
  display: block;
`

export const Label = styled.label`
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: bold;
  display: inline-block;
  font-family: Merriweather, serif;
`

export const Input = styled.input`
  border: 2px solid ${Colors.MainDark};
  padding: 12px;
  font-family: Merriweather, serif;
  margin-bottom: 16px;
  width: 100%;
  border-radius: 0;
  outline: 0;
`

export const Textarea = styled.textarea`
  border: 2px solid ${Colors.MainDark};
  padding: 12px;
  font-family: Merriweather, serif;
  height: 240px;
  margin-bottom: 16px;
  width: 100%;
  border-radius: 0;
  outline: 0;
`
