import React from "react"
import { Helmet } from "react-helmet"

const Seo: React.FC = () => {
  return (
    <Helmet>
      <link key={1} rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        key={2}
        href="https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;0,900;1,400&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  )
}

export default Seo
