import { css } from "styled-components"

export const TitlesFontFamily = `font-family: "Merriweather", serif;`

export const MainTextFontFamily = `font-family: "Noto Sans JP", sans-serif;`

const Typography = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${TitlesFontFamily}
  }

  div,
  p,
  span,
  a,
  li {
    ${MainTextFontFamily}
  }
`

export default Typography
