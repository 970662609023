import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
// @ts-ignore
import ParallaxMousemove from "react-parallax-mousemove"
import { ResponsiveContainer } from "../styled-components/Layout"
import ErrorBoundary from "./ErrorBoundary"
import Header from "./Header"
import { Colors } from "../styled-components/variables"

const HeroWrapper = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  background: rgb(243, 244, 233);
  background: linear-gradient(
    180deg,
    rgba(243, 244, 233, 1) 0%,
    rgba(251, 252, 248, 1) 69%,
    rgba(255, 255, 255, 1) 90%,
    rgba(228, 242, 245, 1) 100%
  );
  overflow: hidden;
`
const MainTitle = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  max-width: 35%;
  padding-top: 10vh;
  padding-bottom: 50px;
  line-height: 1.15;
`
const AbsoluteCenterDiv = styled.div`
  position: absolute;
  left: 50%;
  width: 1px;
  top: 0;
  height: 100%;
  bottom: 0;
`
const HeroTitle = styled.div`
  font-size: 2rem;
  color: ${Colors.MainOrange};
  font-family: Merriweather, serif;
  margin-bottom: 8px;
  display: block;
`
const HeroContainer = styled.div`
  min-height: 100vh;
`

const TextureHeroImg = styled.img``

const Hero: React.FC = () => {
  const imagesQueryData = useStaticQuery(graphql`
    query HeroImagesQuery {
      tablet: file(relativePath: { eq: "ipad.png" }) {
        publicURL
      }
      laptop: file(relativePath: { eq: "laptop.png" }) {
        publicURL
      }
      mobile: file(relativePath: { eq: "iphone.png" }) {
        publicURL
      }
      textureHero: file(relativePath: { eq: "texture-hero-bg.png" }) {
        publicURL
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        publicURL
      }
      dotsHero: file(relativePath: { eq: "dots-hero-bg.png" }) {
        publicURL
      }
    }
  `)

  const mobileConfig = {
    xFactor: 0.14,
    yFactor: 0.18,
    springSettings: {
      stiffness: 500,
      damping: 30,
    },
  }

  const tabletConfig = {
    xFactor: 0.1,
    yFactor: 0.1,
    springSettings: {
      stiffness: 500,
      damping: 30,
    },
  }

  const laptopConfig = {
    xFactor: 0.04,
    yFactor: 0.08,
    springSettings: {
      stiffness: 500,
      damping: 30,
    },
  }

  return (
    <ErrorBoundary>
      <HeroWrapper>
        <Header />
        <ParallaxMousemove
          containerStyle={{
            width: "100%",
            position: "relative",
          }}
        >
          <AbsoluteCenterDiv>
            <ParallaxMousemove.Layer
              layerStyle={{
                position: "absolute",
                left: "50%",
                top: "0",
                transform: "translate(-50%, -20%)",
              }}
              config={{
                xFactor: 0,
                yFactor: 0,
                springSettings: {
                  stiffness: 200,
                  damping: 100,
                },
              }}
            >
              <img
                alt="Background texture."
                key={imagesQueryData.textureHero.publicURL}
                src={imagesQueryData.textureHero.publicURL}
              />
            </ParallaxMousemove.Layer>
            <ParallaxMousemove.Layer
              layerStyle={{
                position: "absolute",
                transform: "translate(-50%, -20%)",
              }}
              config={{
                xFactor: 0,
                yFactor: 0,
                springSettings: {
                  stiffness: 400,
                  damping: 10,
                },
              }}
            >
              <TextureHeroImg
                alt="Background texture 2."
                key={imagesQueryData.dotsHero.publicURL}
                src={imagesQueryData.dotsHero.publicURL}
              />
            </ParallaxMousemove.Layer>
            <ParallaxMousemove.Layer
              layerStyle={{
                position: "absolute",
                transform: "translate(calc(-50% - 400px), -20vh)",
              }}
              config={tabletConfig}
            >
              <img
                alt="Tablet"
                key={imagesQueryData.tablet.publicURL}
                src={imagesQueryData.tablet.publicURL}
              />
            </ParallaxMousemove.Layer>
            <ParallaxMousemove.Layer
              layerStyle={{
                position: "absolute",
                transform: "translate(-40%, -30vh)",
              }}
              config={laptopConfig}
            >
              <img
                alt="Laptop"
                key={imagesQueryData.laptop.publicURL}
                src={imagesQueryData.laptop.publicURL}
              />
            </ParallaxMousemove.Layer>
            <ParallaxMousemove.Layer
              layerStyle={{
                position: "absolute",
                transform: "translate(calc(-50% + 420px), -64vh)",
              }}
              config={mobileConfig}
            >
              <img
                alt="Mobile phone"
                key={imagesQueryData.mobile.publicURL}
                src={imagesQueryData.mobile.publicURL}
              />
            </ParallaxMousemove.Layer>
          </AbsoluteCenterDiv>
          <ResponsiveContainer>
            <HeroContainer>
              <MainTitle>
                Next-gen software solutions for web and mobile
              </MainTitle>
              <HeroTitle>Thoughtfully.</HeroTitle>
              <HeroTitle>Responsibly.</HeroTitle>
              <HeroTitle>Fast.</HeroTitle>
            </HeroContainer>
          </ResponsiveContainer>
        </ParallaxMousemove>
      </HeroWrapper>
    </ErrorBoundary>
  )
}

export default Hero
