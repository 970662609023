import React from "react"
import styled from "styled-components"

import Button from "../styled-components/Button"
import { Colors } from "../styled-components/variables"
import {
  H2,
  H2Subtitle,
  Input,
  Label,
  Textarea,
} from "../styled-components/Layout"
import { graphql, useStaticQuery } from "gatsby"

const Container = styled.div`
  padding: 120px 0 120px;
  overflow: hidden;
  position: relative;
`

const Wrapper = styled.div`
  background: ${Colors.ButtonText};
  width: 640px;
  margin: 0 auto;
  background: #fff;
  padding: 100px 70px;
  clip-path: polygon(0% 5%, 4% 96%, 94% 100%, 100% 0%);
`

const Row = styled.section`
  display: block;
  width: 100%;
`

const BackgroundImg = styled.img`
  position: absolute;
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
`

const ContactUs: React.FC = () => {
  const imagesQueryData = useStaticQuery(graphql`
    query ContactUsQuery {
      background: file(relativePath: { eq: "contact-us-background.png" }) {
        publicURL
      }
    }
  `)

  return (
    <Container>
      <a id="contact-us" />
      <BackgroundImg src={imagesQueryData.background.publicURL} />
      <Wrapper>
        <Row>
          <H2>Contact us!</H2>
        </Row>
        <Row style={{ marginBottom: 40 }}>
          <H2Subtitle>To get a quote, or just because.</H2Subtitle>
        </Row>
        <Row>
          <Label>YOUR NAME</Label>
        </Row>
        <Row>
          <Input placeholder="John Doe" />
        </Row>
        <Row>
          <Label>YOUR EMAIL</Label>
        </Row>
        <Row>
          <Input placeholder="John Doe" />
        </Row>
        <Row>
          <Label>SUBJECT</Label>
        </Row>
        <Row>
          <Textarea placeholder="I want to give you $1,000,000 to implement a web page." />
        </Row>
        <Row>
          <Button>Slap that message</Button>
        </Row>
      </Wrapper>
    </Container>
  )
}

export default ContactUs
